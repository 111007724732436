import {HttpErrorResponse} from '@angular/common/http';
import {AllowedDateType} from '@thebell/data-transfer-objects';
import {throwError} from 'rxjs';

export function toUTC(date: Date): Date {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function handleError(error: HttpErrorResponse) {
  if (error.status === 0) {
    console.error('An error occurred:', error.error);
  } else {
    console.error(`Backend returned code ${error.status}, url was: `, error.url);
  }
  return throwError(() => new Error('Something bad happened; please try again later.'));
}

export function pluralizeRu(count: number, type: AllowedDateType) {
  const cases = [2, 0, 1, 1, 1, 2];
  const wordsMap = {
    [AllowedDateType.DAY]: ['день', 'дня', 'дней'],
    [AllowedDateType.WEEK]: ['неделя', 'недель', 'недель'],
    [AllowedDateType.MONTH]: ['месяц', 'месяца', 'месяцев'],
    [AllowedDateType.YEAR]: ['год', 'года', 'лет'],
  };

  // hotfix for client side, because Apollo not resolved enums and returns the key instead of the value
  type = AllowedDateType[(type as string).toUpperCase() as keyof typeof AllowedDateType];

  const words = wordsMap[type];
  if (!words) {
    return '';
  }
  return words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]];
}
