import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {AllowedProductStatus, Currency, GqlDto, SubscriptionDto} from '@thebell/data-transfer-objects';
import {
  APPLY_DISCOUNT_FOR_PAID_SUBSCRIPTION,
  WRITE_SUBSCRIPTION_RETENTION_HISTORY,
} from '../contracts/api/subscription';
import {WriteRetentionHistoryParams} from '../contracts/params/subscription';

@Injectable()
export class SubscriptionService {
  constructor(private apollo: Apollo) {}

  applyCoupon(
    subscriptionId: number,
    couponCode: string,
    newPlanId: number,
    userAccessKey: string
  ): Promise<SubscriptionDto> {
    return this.apollo
      .mutate<GqlDto<'applyDiscountForPaidSubscription', SubscriptionDto>>({
        mutation: APPLY_DISCOUNT_FOR_PAID_SUBSCRIPTION,
        variables: {key: userAccessKey, id: subscriptionId, couponCode, newPlanId},
        context: {
          headers: {
            access_key: userAccessKey,
          },
        },
      })
      .pipe(map((res) => res.data.applyDiscountForPaidSubscription))
      .toPromise();
  }

  writeRetentionHistory({
    email,
    subscriptionId,
    userChoice,
    userFeedback,
    comment,
  }: WriteRetentionHistoryParams): Promise<boolean> {
    return this.apollo
      .mutate<GqlDto<'writeSubscriptionRetentionHistory', boolean>>({
        mutation: WRITE_SUBSCRIPTION_RETENTION_HISTORY,
        variables: {
          email,
          historyItem: {
            subscriptionId,
            userChoice,
            userFeedback,
            comment,
          },
        },
      })
      .pipe(map((res) => res.data.writeSubscriptionRetentionHistory))
      .toPromise();
  }

  /**
   * @deprecated Its the Temp Fucking Koslyl
   * Быстро заткнули проблему: храним стоимость за месяц, но оплачиваемый период может быть больше
   * высчитываем стоимость price * periodValue и применяем округление
   * закономерно получаем не всегда корректную сумму (не ту что на самом деле списывается)
   */
  getPlanPrice(
    product: AllowedProductStatus,
    periodMonthValue: number,
    currency: Currency,
    couponCode?: number
  ): null | number {
    const uid = `${product.toUpperCase()}_${periodMonthValue}_${currency}`
      + (couponCode ? `_${couponCode}` : '');

    const prices = {
      INVEST_1_USD: 1900,
      INVEST_1_USD_11: 950,
      INVEST_6_USD: 9000,
      INVEST_6_USD_11: 4500,
      INVEST_1_RUB: 1590,
      INVEST_1_RUB_11: 795,
      INVEST_12_RUB: 19080,
      INVEST_12_RUB_6: 13300,
      INVEST_12_RUB_11: 6650
    };

    return prices[uid] || null;
  }
}
