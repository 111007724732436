export enum BankType {
  YOKASSA = 'yokassa',
  PAYPAL = 'paypal',
  INTELLECTMONEY = 'intellectmoney',
  STRIPE = 'stripe',
  BOOSTY = 'boosty',
  FREE = 'free',
  CLOUDPAYMENTS = 'cloudpayments',
  BRAINTREE = 'braintree',
  BONUS = 'bonus',
}

export const subscriptionAccountEnv = {
  [BankType.YOKASSA]: 'subscription_shop_account',
  [BankType.PAYPAL]: 'subscription_shop_account_paypal',
  [BankType.INTELLECTMONEY]: 'subscription_shop_account_intellectmoney',
  [BankType.STRIPE]: 'subscription_shop_account_stripe',
  [BankType.BOOSTY]: 'subscription_shop_account_boosty',
  [BankType.CLOUDPAYMENTS]: 'subscription_shop_account_cloudpayments',
  [BankType.BRAINTREE]: 'subscription_shop_account_braintree',
  [BankType.BONUS]: 'subscription_shop_account_bonus',
};

export const donateAccountEnv = {
  [BankType.YOKASSA]: 'donate_shop_account',
  [BankType.PAYPAL]: 'subscription_shop_account_paypal',
  [BankType.INTELLECTMONEY]: 'subscription_shop_account_intellectmoney',
  [BankType.STRIPE]: 'subscription_shop_account_stripe',
  [BankType.BOOSTY]: 'subscription_shop_account_boosty',
  [BankType.CLOUDPAYMENTS]: 'subscription_shop_account_cloudpayments',
  [BankType.BRAINTREE]: 'subscription_shop_account_braintree',
};
