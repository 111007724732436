import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SocialIconsPipe} from './social-icons.pipe';
import {PluralizeRuDatesPipe} from './pluralize-ru-dates.pipe';
import {PriceFormatPipe} from './price-format.pipe';

@NgModule({
  declarations: [SocialIconsPipe, PluralizeRuDatesPipe, PriceFormatPipe],
  imports: [CommonModule],
  exports: [SocialIconsPipe, PluralizeRuDatesPipe, PriceFormatPipe],
})
export class PipesModule {}
