import {gql} from 'apollo-angular';

export const APPLY_DISCOUNT_FOR_PAID_SUBSCRIPTION = gql`
  mutation applyDiscountForPaidSubscription($key: String!, $id: Int!, $couponCode: String!, $newPlanId: Int!) {
    applyDiscountForPaidSubscription(key: $key, id: $id, couponCode: $couponCode, newPlanId: $newPlanId) {
      id
      email
      status
      retryAttempts
      startedAt
      nextProlongatedAt
      periodValue
      periodType
      amount
      totalAmount
      recurrentPaymentExternalId
      subscriptionPlanId
      deactivatedAt
      acquiringBank
      feedback
      couponCodeId
      createdAt
      updatedAt
      subscriptionPlan {
        id
        product
        name
        currency
        periodType
        periodValue
      }
      couponCode {
        id
        code
        status
        subscriptionPlans {
          couponCodeId
          subscriptionPlanId
          applyCouponCode
          discountPercent
          isDiscounted
          promoPeriodValue
          promoPeriodType
          isOnlyForNew
          isRepetable
          showOriginalPrice
          newPrice
        }
      }
    }
  }
`;

export const WRITE_SUBSCRIPTION_RETENTION_HISTORY = gql`
  mutation writeSubscriptionRetentionHistory($email: String!, $historyItem: WriteSubscriptionRetentionHistoryInput!) {
    writeSubscriptionRetentionHistory(email: $email, historyItem: $historyItem)
  }
`;
